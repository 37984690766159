@import url('https://fonts.googleapis.com/css2?family=Edu+VIC+WA+NT+Beginner:wght@400..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Yellowtail&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');

.love-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  flex-direction: column;
  width: 100vw;
  background-color: rgb(15, 15, 15);
}

.pre-content {
  display: flex;
  align-items: center;
  padding-top: 5rem;
  width: 100vw;
  background-color: rgb(15, 15, 15);
  flex-direction: column;
  gap: 1rem;
  height: 100vh;
}

.carousel {
  padding-top: 2rem;
  width: 80vw;
  border-radius: 10px;
}

.carousel-inner {
  border-radius: 10px;
}

img {
  width: 100vw;
  border-radius: 10px;
}

.boat {
  width: 64px;
  margin-bottom: 2rem;
  margin-top: 3rem;
}

p{
  text-align: center;
  color: white;
  font-family: "Edu VIC WA NT Beginner", cursive;
  font-size: 1.6rem;
}

.description-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90vw;
  margin: auto;
  margin-top: 2rem;
}

hr {
  width: 60vw;
  height: 2px;
  color: white;
  margin: 2rem;
}

h1 {
  color: white;
  font-family: "Anton", sans-serif;
}

.teste {
  margin-bottom: 5rem;
}

.special {
  margin-top: 5rem;
  font-family: "Yellowtail", cursive;
}

.cardos {
  border-radius: 25px;
}

.cardos .inner {
  padding: 15px;
  background: #222;
  color: #fff;
  border-radius:25px;
}

.inner {
  display: flex;
  justify-content: center;
  align-items: center;
}

.font-photo {
  color: #ff00ff;
  font-weight: 600;
  text-shadow: 0px 0px 8px black;
}

h3 {
  margin-bottom: 0;
  font-size: 1.5rem;
}

button {
  padding: 10px;
  border: 2px solid #ff00ff;
  border-radius: 25px;
  background-color: rgb(15, 15, 15);
  color: white;
  font-family: monospace;
  transition: all 500ms;
}

button:hover {
  background-color: rgb(34, 20, 34);
}

.carousel-image {
  width: 100%;
  height: 500px; /* Define a altura das imagens */
  object-fit: cover; /* Faz com que a imagem cubra todo o espaço disponível, mantendo o aspecto */
  display: block;
  margin: 0 auto; /* Centraliza a imagem */
}

.special2 {
  margin-bottom: 5rem;
  font-family: Arial,Helvetica,sans-serif;
  text-transform: uppercase;
  font-weight: 700;
}

.stroke-text {
  color: #ff00ff;
  font-family: Arial,Helvetica,sans-serif;
  font-size: 2rem;
  letter-spacing: -3px;
  text-shadow: 0px 0px 8px #ff00ff;
}

/* Animação de saída (fade out) */
.fade-out {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

/* Animação de entrada (fade in) */
.fade-in {
  opacity: 0;
  animation: fadeIn 0.5s forwards ease;
}

@keyframes fadeIn {
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Spinner */
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #ff00ff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
